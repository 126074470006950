import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgWorkCover from '../assets/image/cover/DzemoIntro.webp'
import imgS3 from '../assets/image/details/dzemo/DzemoImac.jpg'
import imgS4 from '../assets/image/details/dzemo/Dzemo_laptop.jpeg'
import imgS2 from '../assets/image/details/dzemo/smartmockups.jpeg'

// import { device } from "../utils";
const handleLiveWorkClicked = event => {
  event.stopPropagation()
  window.open('https://www.dzemo.com/', '_blank')
}

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">CODING</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {' '}
                  Dzemo is specialized in recruiting and consultancy for
                  companies looking for developers. They help clients to find
                  the best engineers using the unique methodology of hiring only
                  experienced engineers.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  Dzemo
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time Scope</Text>
                <Title variant="cardBig" className="mt-3">
                  3 Months
                </Title>
              </Col>
              <Col lg="4">
                <Button onClick={handleLiveWorkClicked} arrowRight>
                  Live work
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="12" className="mb-5">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>

              <Col xs="12" className="mb-5">
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col>
              <Col xs="12" className="mb-5">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Process
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  1. Briefing
                </Title>
                <Text variant="p">
                  As a part of the Remote Team, I was responsible for the
                  initial research and competitive analysis. The next stage
                  required implementing Figma designs and building assigned
                  features in collaboration with other developers.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  2. Deliverables
                </Title>
                <Text variant="p">
                  - Research, Concept, and UX <br />
                  - Building a Responsive Website as a part of the Developer
                  Team
                  <br />
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Strategy
                </Title>
                <Text variant="p">
                  Taking into account the scaling of the project in the future,
                  we decided to use React for the frontend and Strapi for the
                  backend.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  4. Key Learnings
                </Title>
                <Text variant="p">
                  As a collaboration tool for the remote daily standups, we used
                  Trello and Slack. It was my first experience working on the
                  Remote Team, and I found integration of the features and
                  solving merge conflicts quite challenging. I also discovered
                  that customizing the Bootstrap framework to match provided
                  designs its not an easy going process.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        {/* <Section mt="22%" bg="dark">
          <Container>
            {/* <Row

              css={`
                margin-top: -40%;
              `}
            >
              { <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>}
            </Row> }

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
